import { Controller } from "@hotwired/stimulus"
import { loadPayOneScripts } from "../src/payone-loader"
import { buildFieldConfiguration, buildRequestConfiguration } from "../src/payone-configuration"
import { toggleCssActive } from "../src/dom"

const PAYONE_RESPONSE_STATUS_VALID = "VALID"
const PAYMENT_METHOD_CREDIT_CARD = "credit_card"
const CSS_CLASSES = {
  expanded: "is--expanded",
  visible: "is--visible"
}

export default class extends Controller {
  static targets = [
    "buyButton",
    "mainForm",
    "mobilePaymentSelectionTrigger",
    "movieGiftBooleanInput",
    "movieGiftMessageInput",
    "paymentButton",
    "paymentDetails",
    "paymentMethodInput",
    "pseudoCardPanInput"
  ]

  static values = {
    initialPaymentMethod: String,
    payOneMode: String,
    payOneMerchantId: String,
    payOneAccountId: String,
    payOnePortalId: String,
    payOneHash: String,
    useGifts: Boolean,
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    window.Turbo?.cache?.exemptPageFromPreview()

    // Payone invokes a global callback once the CC data has been checked. We want to end up in here, though, so we have to redirect it slightly.
    window.payOneCallbackFunction = this.creditCardCheckCallback.bind(this)

    // When the User pays using CC, we prevent the actual form submission until PayOne has verified the CC data.
    this.allowCreditCardFormSubmission = false

    this.loadCreditCardScripts()
    this.switchPaymentForm(this.initialPaymentMethod)
    this.isExpanded = false
  }

  // Actions

  expandPaymentSelection() {
    if (this.isExpanded) {
      this.isExpanded = false
      return
    }

    this.paymentButtonTargets.forEach(element => element.classList.add(CSS_CLASSES.visible))
    this.isExpanded = true
  }

  selectPaymentMethod(event) {
    const paymentMethod = event.target.dataset.paymentMethod
    if (!paymentMethod) { return }

    this.switchPaymentForm(paymentMethod)
  }

  // Internals

  switchPaymentForm(paymentMethod) {
    this.paymentMethodInputTarget.value = paymentMethod
    this.paymentMethod = paymentMethod
    this.paymentDetailsTargets.forEach(element => { toggleCssActive(element, element.dataset.paymentMethod == paymentMethod) })
    this.paymentButtonTargets.forEach(element => {
      toggleCssActive(element, element.dataset.paymentMethod == paymentMethod)
      element.classList.remove(CSS_CLASSES.visible)
    })

    this.updateBuyButtonText()
  }

  updateBuyButtonText() {
    if (!this.hasBuyButtonTarget) { return }

    const rawTextJson = this.buyButtonTarget.dataset.titles
    if (!rawTextJson) { return }

    const titles = JSON.parse(rawTextJson)
    const newText = titles[this.paymentMethod] || titles["default"]
    if (!newText) { return }

    this.buyButtonTarget.value = newText
  }

  handleFormSubmit(event) {
    this.storeGiftInformation()

    if (this.paymentMethod != PAYMENT_METHOD_CREDIT_CARD) { return }
    if (this.allowCreditCardFormSubmission) { return }

    event.preventDefault()
    event.stopPropagation()

    if (this.payOneIframes.isComplete()) {
      this.payOneIframes.creditCardCheck("payOneCallbackFunction")
    } else {
      // TODO: Error handling
      console.error("Failed to complete CreditCard data check")
    }
  }

  // Gift handling (optional)

  storeGiftInformation() {
    if (!this.useGiftsValue) { return }
    if (!this.hasMovieGiftBooleanInputTarget) { return }
    if (!this.hasMovieGiftMessageInputTarget) { return }

    const giftTriggerElement = document.querySelector("[data-order-payment-gift-trigger]")

    if (giftTriggerElement?.classList.contains(CSS_CLASSES.expanded)) {
      const message = document.querySelector("[data-order-payment-gift-message]")?.value

      this.movieGiftBooleanInputTarget.value = "1"
      this.movieGiftMessageInputTarget.value = message
    } else {
      this.movieGiftBooleanInputTarget.value = null
      this.movieGiftMessageInputTarget.value = null
    }
  }

  // PayOne integration

  async loadCreditCardScripts() {
    await loadPayOneScripts()

    this.creditCardFieldConfiguration = buildFieldConfiguration("de")
    this.creditCardRequestConfiguration = buildRequestConfiguration({
      mode: this.payOneModeValue,
      merchantId: this.payOneMerchantIdValue,
      accountId: this.payOneAccountIdValue,
      portalId: this.payOnePortalIdValue,
      hash: this.payOneHashValue
    })

    this.payOneIframes = new Payone.ClientApi.HostedIFrames(this.creditCardFieldConfiguration, this.creditCardRequestConfiguration)
  }

  creditCardCheckCallback(response) {
    if (response.status !== PAYONE_RESPONSE_STATUS_VALID) {
      // TODO: Error handling
      return
    }

    this.allowCreditCardFormSubmission = true
    this.pseudoCardPanInputTarget.value = response.pseudocardpan
    this.mainFormTarget.requestSubmit()
  }

  get initialPaymentMethod() {
    return this.initialPaymentMethodValue || this.paymentDetailsTargets[0].dataset.paymentMethod
  }
}
