import { Controller } from "@hotwired/stimulus"

const CSS_CLASSES = { active: "is--active" }

export default class extends Controller {
  static targets = ["spinner"]

  showSpinner() {
    // If we are using a nested spinner, show just that one.
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add(CSS_CLASSES.active)
      return
    }

    // Otherwise attempt to show a global one.
    const globalSpinnerElement = document.querySelector("[data-global-spinner")
    if (!globalSpinnerElement) { return }

    globalSpinnerElement.classList.add(CSS_CLASSES.active)
  }
}
