import { Controller } from "@hotwired/stimulus"
import { EventBus } from "../src/event_bus"

const CSS_CLASSES = { expanded: "is--expanded" }
const EVENT_EXPANDED = "expandController:expanded"

export default class extends Controller {
  static targets = ["icon", "content"]
  static values = { autoCollapse: Boolean }

  connect() {
    this.isExpanded = this.element.classList.contains(CSS_CLASSES.expanded)
    this.isCurrentEventTrigger = false

    this.boundExpandedHandler = EventBus.on(EVENT_EXPANDED, () => { this.handleExpandedEvent() })
  }

  updateView() {
    this.element.classList.toggle(CSS_CLASSES.expanded, this.isExpanded)
  }

  toggle() {
    this.isExpanded = !this.isExpanded
    this.updateView()

    if (this.isExpanded) {
      this.isCurrentEventTrigger = true
      EventBus.emit(EVENT_EXPANDED)
    } else {
      this.isCurrentEventTrigger = false
    }
  }

  handleExpandedEvent() {
    if (this.isCurrentEventTrigger) {
      this.isCurrentEventTrigger = false
      return
    }

    if (!this.autoCollapseValue) { return }
    if (!this.isExpanded) { return }

    this.toggle()
  }
}
